<template>
  <div
    class="d-flex flex-row flex-grow-1 animate__animated animate__fadeIn"
    style="width: 100%;"
  >
    <ValidationObserver
      v-if="pendingFuelSales.length > 0"
      tag="div"
      class="d-flex flex-row flex-grow-1"
      v-slot="{ handleSubmit }"
    >
      <form
        style="width: 100%;"
        @submit.prevent="handleSubmit(confirmationAlert)"
      >
        <template>
          <div class="d-flex flex-column flex-fill flex-grow-1 ft">
            <div
              class="d-flex flex-row flex-grow-1"
              style="justify-content: space-between;"
            >
              <v-alert v-if="recordedFuelSales.length > 0" dense type="warning">
                <span class="font font-weight-medium font-size-sm"
                  >Total sales recorded due to price change -
                  {{
                    recordedFuelSales.reduce(
                      (acc, item) => (acc += item.totalAmountSold),
                      0
                    )
                      | currencyFormat(
                        currency && currency.length > 0
                          ? currency[0].code
                          : "GHS"
                      )
                  }}</span
                >
              </v-alert>
              <v-alert type="success" dense>
                <span class="font text-uppercase font-weight-bold font-size-sm">
                  Total Fuel Sales Revenue -
                  {{
                    (sum(recordedFuelSales, "totalAmountSold") +
                      sum(
                        pumps.reduce((acc, pump) => acc.concat(pump), []),
                        "totalAmountSold"
                      ) +
                      trackInputAmount.totalCreditCollection)
                      | currencyFormat(
                        currency && currency.length > 0
                          ? currency[0].code
                          : "GHS"
                      )
                  }}
                </span>
              </v-alert>
              <v-alert type="success" dense>
                <span class="font text-uppercase font-weight-bold font-size-sm">
                  Fuel Revenue -
                  {{
                    (sum(recordedFuelSales, "totalAmountSold") +
                      sum(
                        pumps.reduce((acc, pump) => acc.concat(pump), []),
                        "totalAmountSold"
                      ))
                      | currencyFormat(
                        currency && currency.length > 0
                          ? currency[0].code
                          : "GHS"
                      )
                  }}
                </span>
              </v-alert>
              <v-alert
                v-if="trackInputAmount.totalCreditCollection > 0"
                type="success"
                dense
              >
                <span class="font text-uppercase font-weight-bold font-size-sm">
                  Credit Collection -
                  {{
                    trackInputAmount.totalCreditCollection
                      | currencyFormat(
                        currency && currency.length > 0
                          ? currency[0].code
                          : "GHS"
                      )
                  }}
                </span>
              </v-alert>
            </div>
            <div class="d-flex flex-column flex-grow-1 pa-2  grey lighten-4">
              <v-expansion-panels flat>
                <v-expansion-panel
                  v-for="record in pendingFuelSales"
                  :key="record.key"
                >
                  <v-expansion-panel-header
                    v-if="record.values && record.values.length > 0"
                  >
                    <span class=" ft font-weight-bold">
                      Pump:
                      <v-chip small label class=" ft mr-2">{{
                        record.values[0].pumpAssignAttendantId.pumpId.name
                      }}</v-chip>
                      Number Of Nozzles:
                      <v-chip small label class="ft">
                        {{
                          record.values[0].pumpAssignAttendantId.pumpId
                            .numberOfNozzles
                        }}</v-chip
                      >
                    </span>
                    <span class="ft font-weight-medium">
                      Attendant Name:
                      <v-chip small label class="ft">
                        {{
                          record.values[0].pumpAssignAttendantId.attendantId
                            .name
                        }}
                      </v-chip>
                    </span>
                    <span class="ft font-weight-medium font-weight-bold">
                      Fuel Sales - ({{
                        record.values[0].pumpAssignAttendantId.pumpId.name
                      }}):
                      <v-chip
                        small
                        label
                        color="primary"
                        class="font font-weight-medium font-size-md mx-3"
                      >
                        {{
                          record.values.reduce(
                            (acc, sale) =>
                              (acc += parseFloat(sale.totalAmountSold)),
                            0
                          )
                            | currencyFormat(
                              currency && currency.length > 0
                                ? currency[0].code
                                : "GHS"
                            )
                        }}
                      </v-chip>
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-if="record && record.values.length > 0"
                      class="d-flex flex-column flex-grow-1 flex-wrap"
                    >
                      <div
                        v-for="(pump, idx) in record.values"
                        :key="pump.id"
                        class="d-flex flex-column flex-fill flex-grow-1 flex-shrink-1 px-3 ma-2"
                        style="border-radius: 8px;"
                        :style="{
                          backgroundColor: !pump.isStockAvailable
                            ? 'rgb(191 0 0 / 15%)'
                            : '#8272721c'
                        }"
                      >
                        <div
                          v-if="pump.productId"
                          class="d-flex flex-row font-weight-bold black--text mt-3"
                          style="justify-content: space-between;"
                        >
                          <span class="font-weight-bold"
                            >{{ `Nozzle ${idx + 1}` }} /
                            {{ pump.productId.name }}
                          </span>
                          <v-chip
                            v-if="
                              !pump.isStockAvailable ||
                                pump.isStockAvailable.quantity === 0
                            "
                            label
                            small
                            class="mx-3"
                            color="red"
                            ><span
                              class="font white--text font-weight-medium font-size-sm"
                              >No stock available for this product</span
                            ></v-chip
                          >
                        </div>
                        <div class="d-flex flex-row flex-grow-1 flex-fill mt-1">
                          <ValidationProvider
                            tag="div"
                            class="d-flex flex-grow-1 flex-row"
                            v-slot="{ errors }"
                            rules="required|numeric"
                          >
                            <v-text-field
                              :error-messages="errors[0]"
                              label="Opening Meter"
                              readonly
                              background-color="white"
                              v-model="pump.openingMeter"
                              class="font-weight-medium ma-1"
                              hint="Opening meter readings of the pump"
                              outlined
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|numeric"
                          >
                            <v-text-field
                              label="Closing Meter"
                              v-model="pump.closingMeter"
                              :error-messages="
                                errors[0] ||
                                parseInt(pump.closingMeter) <
                                  parseInt(pump.openingMeter)
                                  ? 'Invalid closing meter value provided'
                                  : ''
                              "
                              background-color="white"
                              :disabled="
                                !pump.isStockAvailable ||
                                  pump.isStockAvailable.quantity === 0
                              "
                              class="font-weight-medium ma-1"
                              hint="Closing meter readings of the pump"
                              outlined
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|numeric"
                          >
                            <v-text-field
                              label="Sales (Litres)"
                              readonly
                              background-color="white"
                              :error-messages="
                                errors[0] ||
                                parseInt(pump.openingMeter) >
                                  parseInt(pump.closingMeter)
                                  ? 'Invalid closing meter value provided'
                                  : ''
                              "
                              class="font-weight-medium ma-1"
                              v-model="pump.meterSold"
                              hint="Sales calculation is done per litres"
                              outlined
                            />
                          </ValidationProvider>

                          <v-text-field
                            v-if="pump.price"
                            label="Price/Litre"
                            :prefix="
                              currency && currency.length > 0
                                ? currency[0].symbol
                                : 'GHS'
                            "
                            class="font-weight-medium ma-1"
                            hint="Price per litre"
                            outlined
                            readonly
                            background-color="white"
                            v-model="pump.price"
                            color="primary"
                          />
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              label="Total Amount"
                              background-color="white"
                              readonly
                              :error-messages="errors[0]"
                              :prefix="
                                currency && currency.length > 0
                                  ? currency[0].symbol
                                  : 'GHS'
                              "
                              v-model="pump.totalAmountSold"
                              class="font-weight-medium ma-1"
                              hint="Total amount"
                              outlined
                            />
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>

          <div class="d-flex flex-column flex-fill flex-grow-1 ft mt-1">
            <RecordFuelSegregations
              :date="date"
              :modeOfPayments="modeOfPaymentList"
              @openRevenueDialog="open('generate')"
              @addRevenueRow="addRevenueRow"
              :pumps="pumps"
              :currency="currency"
              :recordedFuelSalesList="recordedFuelSales"
              :totalFuelSales="trackInputAmount.totalFuelSales"
              :recordFuelSegregation="recordFuelSegregation"
              :totalCreditCollection="trackInputAmount.totalCreditCollection"
            />
          </div>
          <div class="d-flex flex-column flex-grow-1 flex-fill mt-3">
            <div class="d-flex flex-row flex-grow-1 my-1">
              <v-chip
                label
                color="primary"
                class=" ft font-weight-medium text-uppercase box-shadow-light"
              >
                Add Credit Sales And Collections From Customers
              </v-chip>
              <div class="d-flex flex-row flex-grow-1 mt-n1 ml-3">
                <v-autocomplete
                  v-model="query"
                  :items="searchResults"
                  :search-input.sync="search"
                  chips
                  clearable
                  hide-details
                  return-object
                  flat
                  :loading="subLoading"
                  dense
                  hide-selected
                  :disabled="
                    sum(recordedFuelSales, 'totalAmountSold') +
                      sum(
                        pumps.reduce((acc, pump) => acc.concat(pump), []),
                        'totalAmountSold'
                      ) +
                      trackInputAmount.totalCreditCollection <=
                      trackInputAmount.totalFuelSales
                  "
                  append-icon="search"
                  class=" ft grey--text text--darken-2 font-weight-medium font-size-md box-shadow-light"
                  item-text="name"
                  :label="
                    'Search for customers by their name and click to add to sales ...'
                  "
                  solo
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="font font-weight-medium">
                        Search through all your
                        <strong>customer</strong> to record sales
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      label
                      :input-value="selected"
                      color="primary"
                      small
                      class="white--text"
                      v-on="on"
                    >
                      <span
                        class="ft font-weight-medium"
                        v-text="item.name"
                      ></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <div class="d-flex flex-row flex-grow-1">
                      <span class="ft font-weight-medium font-size-md">{{
                        item.name
                      }}</span>
                      <v-chip
                        small
                        label
                        color="primary"
                        class="ft ml-4 font-weight-medium font-size-sm"
                        >{{
                          item.businessRegistrationNumber
                            ? "Business Customer"
                            : "Customer"
                        }}</v-chip
                      >
                    </div>
                  </template>
                </v-autocomplete>
              </div>
            </div>
            <div
              v-if="customerSales.length > 0"
              class="d-flex flex-column flex-grow-1 my-1 grey lighten-4 pt-2 pl-2 pr-2 pb-1"
              style="border-radius: 3px;"
            >
              <div
                v-for="(customerSale, index) in customerSales"
                :key="customerSale.id"
                class="d-flex flex-column flex-grow-1 mb-1 white pa-3  box-shadow-light "
              >
                <div class="d-flex flex-row mt-3 flex-grow-1">
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-row flex-grow-1"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-text-field
                      label="Customer Name"
                      dense
                      class="ft font-weight-medium font-size-md"
                      prepend-icon="people"
                      readonly
                      :error-messages="errors[0]"
                      v-model="customerSale.name"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-row flex-grow-1"
                    v-slot="{ errors }"
                    rules="required|decimals"
                  >
                    <v-text-field
                      label="Customer Acc. Balance"
                      dense
                      readonly
                      :error-messages="errors[0]"
                      :prefix="
                        currency && currency.length > 0
                          ? currency[0].symbol
                          : 'GHS'
                      "
                      prepend-icon="credit_card"
                      v-model="customerSale.amount"
                      class="ft font-weight-medium font-size-md"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-row flex-grow-1"
                    v-slot="{ errors }"
                    rules="required|decimals"
                  >
                    <v-text-field
                      label="Credit Sale"
                      prepend-icon="shopping_basket"
                      v-model="customerSale.creditSales"
                      class="ft font-weight-medium font-size-md"
                      dense
                      :prefix="
                        currency && currency.length > 0
                          ? currency[0].symbol
                          : 'GHS'
                      "
                      :error-messages="errors[0]"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    tag="div"
                    class="d-flex flex-row flex-grow-1"
                    v-slot="{ errors }"
                    rules="required|decimals"
                  >
                    <v-text-field
                      label="Credit Collection"
                      prepend-icon="credit_card"
                      v-model="customerSale.creditCollection"
                      class="ft font-weight-medium font-size-md"
                      :prefix="
                        currency && currency.length > 0
                          ? currency[0].symbol
                          : 'GHS'
                      "
                      dense
                      :error-messages="errors[0]"
                    />
                  </ValidationProvider>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        color="red"
                        @click.stop="removeCustomer(index)"
                        small
                      >
                        <i class="material-icons-outlined">remove</i>
                      </v-btn>
                    </template>
                    <span class="ft font-weight-medium font-size-sm"
                      >Remove Customer</span
                    >
                  </v-tooltip>
                </div>
              </div>
            </div>
            <v-alert
              v-if="!isAccountsTallied"
              dense
              type="error"
              class="mx-2 mt-2"
            >
              <span class="font font-weight-medium font-size-sm"
                >Account not tallied, kindly reaccess. Amount can be +/- 15
                cedis</span
              >
            </v-alert>
          </div>
          <div class="d-flex flex-column flex-fill flex-grow-1 ft mt-1">
            <CustomerCreditSales />
          </div>
          <div class="d-flex flex-column flex-grow-1 flex-fill mt-3">
            <div
              class="d-flex flex-column flex-grow-1 grey lighten-4 "
              style="border-radius: 3px;"
              v-if="expenses.length > 0"
            >
              <span
                class=" font font-size-md ml-2 mt-2 text-uppercase font-weight-medium"
                >Other Expenses</span
              >
              <v-slide-y-transition group>
                <div
                  v-for="(expense, index) in expenses"
                  :key="index"
                  class="d-flex flex-column flex-grow-1 my-1 white pa-2 ma-2 "
                >
                  <div class="d-flex flex-row flex-grow-1 pl-3 pr-3 pt-3 pb-1">
                    <ValidationProvider
                      v-slot="{ errors }"
                      tag="div"
                      rules="required"
                      class="d-flex flex-grow-1"
                    >
                      <v-text-field
                        autofocus
                        v-model="expense.name"
                        label="Expenses Name"
                        prepend-icon="badge"
                        class=" ft font-weight-medium font-size-md"
                        dense
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      tag="div"
                      rules="required|decimals"
                      class="d-flex flex-grow-1"
                    >
                      <v-text-field
                        label="Expenses Amount/Value"
                        prepend-icon="credit_card"
                        v-model="expense.value"
                        :prefix="
                          currency && currency.length > 0
                            ? currency[0].symbol
                            : 'GHS'
                        "
                        class="ft font-weight-medium font-size-md"
                        dense
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                    <ValidationProvider tag="div" class="d-flex flex-grow-1">
                      <v-text-field
                        label="Comment"
                        v-model="expense.comment"
                        prepend-icon="edit"
                        class="ft font-weight-medium font-size-md"
                        dense
                      />
                    </ValidationProvider>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          color="red"
                          @click.stop="removeExpensesRow(index)"
                          small
                        >
                          <i class="material-icons-outlined">remove</i>
                        </v-btn>
                      </template>
                      <span class="font font-weight-medium font-size-sm"
                        >Remove Item</span
                      >
                    </v-tooltip>
                  </div>
                </div>
              </v-slide-y-transition>

              <div
                class="d-flex flex-row justify-end my-2 mr-2 mb-2 flex-grow-1"
              >
                <v-btn
                  :disabled="
                    sum(recordedFuelSales, 'totalAmountSold') +
                      sum(
                        pumps.reduce((acc, pump) => acc.concat(pump), []),
                        'totalAmountSold'
                      ) +
                      trackInputAmount.totalCreditCollection <=
                      trackInputAmount.totalFuelSales
                  "
                  @click="addExpensesRow"
                  small
                  color="primary"
                >
                  <span
                    class="font font-weight-medium font-size-sm"
                    style="font-size: 12px !important;"
                  >
                    Add New Expense
                  </span>
                </v-btn>
              </div>
            </div>
            <div
              v-else
              class="d-flex flex-column flex-grow-1 my-1 justify-center grey lighten-4 pa-5"
              style="align-items:center;"
            >
              <img src="@/assets/online-payment.svg" style="width: 80px;" />
              <span class="font font-weight-medium"
                >Record any other expenses as part of the daily sales here
                <kbd class="ml-1">{{ date }}</kbd></span
              >
              <span class="font font-weight-medium font-size-sm"
                >Expenses may include funds disburse for work use etc.
              </span>
              <v-btn
                @click="addExpensesRow"
                small
                :disabled="
                  sum(recordedFuelSales, 'totalAmountSold') +
                    sum(pumps, 'totalAmountSold') <=
                    trackInputAmount.totalFuelSales
                "
                color="primary"
                class="mt-2"
              >
                <span
                  class="font font-weight-medium font-size-sm"
                  style="font-size: 12px !important;"
                  >Add Expenses</span
                >
              </v-btn>
            </div>
          </div>
          <div class="d-flex flex-column flex-fill flex-grow-1 ft">
            <RecordFailedTransactions
              :currency="currency"
              :date="date"
              :loading="loading"
              :failedTransactions="failedTransactions"
              @addFailedTransaction="addFailedTransaction"
              @removeFailedTransaction="removeFailedTransaction"
              :totalCreditCollection="trackInputAmount.totalCreditCollection"
            />
            <MDsBills
              :currency="currency"
              :bills="bills"
              :date="date"
              :pumps="pumps"
              :totalFuelSales="trackInputAmount.totalFuelSales"
              :recordedFuelSalesList="recordedFuelSales"
              :totalCreditCollection="trackInputAmount.totalCreditCollection"
              @addBillRow="addBillRow"
            />
          </div>
          <div
            class="d-flex flex-column flex-grow-1 flex-fill pa-3 grey lighten-4 mt-1"
          >
            <div
              v-for="(rtt, index) in trackInputAmount.returnToTank"
              :key="index"
              class="d-flex flex-row mt-1 font-weight-medium text-uppercase px-3 white py-3"
            >
              <div class="ft font-weight-medium font-size-md mt-3">
                Return To Tank (RTT)
              </div>
              <v-autocomplete
                dense
                :items="products"
                item-value="id"
                item-text="name"
                v-model="rtt.productId"
                outlined
                prepend-icon="sanitizer"
                return-object
                class=" ft font-weight-medium font-size-sm mt-1 ml-2"
                hide-details
                label="Choose a product"
              >
                <template #item="{item}">
                  <span class="font font-weight-medium font-size-sm">{{
                    item.name
                  }}</span>
                </template>
              </v-autocomplete>
              <v-text-field
                v-model="rtt.liters"
                dense
                outlined
                hide-details
                prepend-icon="credit_card"
                class="ml-4 mt-1 ft font-weight-medium font-size-sm"
                :prefix="'Lts'"
                label="Total Amount In Litres"
              />
              <v-btn
                v-if="trackInputAmount.returnToTank.length > 1"
                small
                text
                @click.stop="removeRTTRow(index)"
                icon
                class="mt-2 mx-1"
                color="primary"
              >
                <i class="material-icons-outlined">remove</i>
              </v-btn>
            </div>
            <div class="d-flex flex-row flex-grow-1 justify-start pt-3">
              <v-btn
                :disabled="
                  trackInputAmount.returnToTank.length === products.length
                "
                small
                @click.stop="addRTTRow"
                color="primary"
              >
                <span
                  class=" ft font-weight-medium font-size-sm"
                  style="font-size: 12px !important;"
                  >Add New Row</span
                >
              </v-btn>
            </div>
          </div>
        </template>
      </form>
    </ValidationObserver>
    <div v-else class="d-flex flex-row flex-grow-1 justify-center">
      <EmptyPage
        :image="require(`@/assets/folder.svg`)"
        :title="'No Pending Fuel Sales / End of day sales'"
        :imageWidth="'100px'"
        :subTitle="'Finish up here at once / close up'"
      />
    </div>
    <template v-if="date !== ''">
      <AmountAccountedFor
        style="bottom: 11% !important"
        :currency="currency"
        label="Amt. Left"
        icon="point_of_sale"
        :totalAmount="totalFuelRecordings - trackInputAmount.totalFuelSales"
      />
      <AmountAccountedFor
        :currency="currency"
        icon="credit_score"
        label="Amt. Added"
        :totalAmount="trackInputAmount.totalFuelSales"
      />
    </template>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import { currencyFormat } from "../../../../filters/currencyFormat";
import { from } from "rxjs";
import { toArray, map, switchMap, mergeMap } from "rxjs/operators";
import { decimals, roundAccurately } from "../../../utils/resolvers";
import moment from "moment";
import { debounce } from "lodash";
extend("numeric", {
  ...numeric,
  message: "Input field must contain only numbers"
});
extend("required", {
  ...required,
  message: "Input field is required"
});
extend(decimals, decimals);
export default {
  name: "PendingFuelSales",
  components: {
    ValidationProvider,
    ValidationObserver,
    MDsBills: () => import(`../MDsBills`),
    EmptyPage: () => import(`../../../../components/EmptyPage`),
    RecordFuelSegregations: () => import(`../RecordFuelSegregations`),
    CustomerCreditSales: () => import(`../CustomerCreditSales`),
    AmountAccountedFor: () => import(`../AmountAccountedFor`),
    RecordFailedTransactions: () => import(`../RecordOccurrences`)
  },
  props: {
    branchId: {
      type: String,
      default: null
    },
    date: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    currency: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    finalSales: true,
    bills: [],
    recordFuelSegregation: [],
    expenses: [],
    records: [],
    pumps: [],
    search: null,
    query: null,
    customerSales: [],
    failedTransactions: [],
    trackInputAmount: {
      id: null,
      totalFuelSales: 0, // this is the sum of the pumps value, the recorded sales and credit collection
      totalCreditCollection: 0,
      totalCreditSales: 0,
      totalLubeSales: 0,
      totalShopSales: 0,
      totalExpenses: 0,
      totalFailedTransactions: 0,
      totalBills: 0,
      returnToTank: [
        {
          productId: null,
          liters: 0
        }
      ]
    },
    isAccountsTallied: true,
    totalFuelRecordings: 0, // recordings that will be activated on adding of segregations
    isInputValid: true
  }),
  computed: {
    ...mapGetters({
      recordedFuelSales: "sales/getAlreadyRecordedFuelSales",
      pendingFuelSales: "sales/getAllPendingFuelSales",
      overAllSalesRecords: "sales/getAllOverallSalesCalculation",
      modeOfPaymentList: "prices/getModeOfPaymentList",
      products: "products/getProductList",
      subLoading: "getIsSubLoading",
      searchResults: "customers/getCustomerSearchResults"
    })
  },
  watch: {
    overAllSalesRecords(value) {
      this.bills = value && value.bills ? [...value.bills] : [];
      this.customerSales =
        value && value.customerSales ? [...value.customerSales] : [];
      this.recordFuelSegregation =
        value && value.recordFuelSegregation
          ? [...value.recordFuelSegregation]
          : [];
      this.expenses = value && value.expenses ? [...value.expenses] : [];
      if (value && value.totalTrackAmount) {
        this.trackInputAmount = { ...value.totalTrackAmount };
      }
      this.failedTransactions =
        value && value.failedTransactions ? [...value.failedTransactions] : [];
    },
    "trackInputAmount.totalFuelSales"(value) {
      this.totalFuelRecordings =
        this.sum(this.recordedFuelSales, "totalAmountSold") +
        this.sum(
          this.pumps.reduce((acc, pump) => acc.concat(pump), []),
          "totalAmountSold"
        ) +
        this.trackInputAmount.totalCreditCollection;

      const balance = this.totalFuelRecordings - value;
      this.isAccountsTallied = balance >= -15;
    },
    "trackInputAmount.totalCreditCollection"(value) {
      this.totalFuelRecordings =
        this.sum(this.recordedFuelSales, "totalAmountSold") +
        this.sum(
          this.pumps.reduce((acc, pump) => acc.concat(pump), []),
          "totalAmountSold"
        ) +
        value;

      this.isAccountsTallied =
        Number(this.totalFuelRecordings) -
          Number(this.trackInputAmount.totalFuelSales) >=
        -15;
    },
    pendingFuelSales: {
      handler(payload) {
        payload?.forEach(record => {
          record.values?.map(pumpItem => {
            if (Number(pumpItem.closingMeter) < Number(pumpItem.openingMeter)) {
              this.isInputValid = !this.isInputValid;
            } else this.isInputValid = true;
            let results =
              Number(pumpItem.closingMeter) - Number(pumpItem.openingMeter);
            pumpItem.meterSold = isNaN(results) || results < 0 ? 0 : results;
            let totalAmount = parseFloat(results * pumpItem.price).toFixed(2);
            pumpItem.totalAmountSold =
              isNaN(totalAmount) || totalAmount < 0 ? 0 : totalAmount;
          });
        });
        // set up the pump total amount from this
        const pumps$ = from(payload).pipe(
          map(record => record.values),
          switchMap(pumps =>
            from(pumps).pipe(
              map(pump => {
                return {
                  id: pump?.id,
                  nozzleId: pump?.nozzleId?.id,
                  price: pump?.price,
                  openingMeter: pump?.openingMeter,
                  closingMeter: pump?.closingMeter,
                  totalAmountSold: Number(pump.totalAmountSold) ?? 0
                };
              })
            )
          ),
          toArray()
        );
        pumps$.subscribe(pump => {
          this.pumps = pump;
          this.totalFuelRecordings =
            this.sum(this.recordedFuelSales, "totalAmountSold") +
            this.sum(
              pump.reduce((acc, pump) => acc.concat(pump), []),
              "totalAmountSold"
            ) +
            this.trackInputAmount.totalCreditCollection;
        });
      },
      deep: true
    },
    recordFuelSegregation: {
      handler(sales) {
        this.trackInputAmount.totalFuelSales = roundAccurately(
          this.sum(sales, "amount") +
            this.sum(this.customerSales, "creditSales") +
            this.sum(this.expenses, "value") +
            this.sum(this.bills, "amount"),
          2
        );
      },
      deep: true
    },
    customerSales: {
      handler(sales) {
        this.trackInputAmount.totalFuelSales = roundAccurately(
          this.sum(sales, "creditSales") +
            this.sum(this.recordFuelSegregation, "amount") +
            this.sum(this.expenses, "value") +
            this.sum(this.bills, "amount"),
          2
        );

        this.trackInputAmount.totalCreditCollection = this.sum(
          sales,
          "creditCollection"
        );
      },
      deep: true
    },
    expenses: {
      handler(expenses) {
        this.trackInputAmount.totalFuelSales = roundAccurately(
          this.sum(expenses, "value") +
            this.sum(this.recordFuelSegregation, "amount") +
            this.sum(this.customerSales, "creditSales") +
            this.sum(this.bills, "amount"),
          2
        );
      },
      deep: true
    },
    bills: {
      handler(bills) {
        this.trackInputAmount.totalFuelSales = roundAccurately(
          this.sum(bills, "amount") +
            this.sum(this.recordFuelSegregation, "amount") +
            this.sum(this.customerSales, "creditSales") +
            this.sum(this.expenses, "value"),
          2
        );
      },
      deep: true
    },
    search: debounce(function(query) {
      if (query) this.$store.dispatch("customers/searchCustomers", { query });
    }, 500),
    query(payload) {
      if (payload) {
        const { name, id, email, phone, contact, amount, slug } = payload;
        let results = {
          name,
          id,
          email,
          phone,
          contact,
          slug,
          isBusiness: !!payload.businessRegistrationNumber,
          creditSales: 0,
          creditCollection: 0,
          amount
        };
        if (
          !this.customerSales.find(
            customer =>
              customer.email === results.email && customer.slug === results.slug
          )
        ) {
          this.customerSales = [
            ...this.customerSales.filter(
              customer =>
                customer.email !== results.email &&
                customer?.slug !== results.slug
            ),
            results
          ];
        }
      }
    }
  },

  filters: {
    currencyFormat
  },
  methods: {
    sum(items, key) {
      return items.reduce(
        (acc, item) =>
          (acc += isNaN(parseFloat(item[key])) ? 0 : parseFloat(item[key])),
        0
      );
    },
    addRevenueRow(payload) {
      this.recordFuelSegregation.push(payload);
    },
    addBillRow(payload) {
      this.bills.push(payload);
    },
    addExpensesRow() {
      this.expenses.push({
        name: "",
        value: "",
        comment: ""
      });
    },
    removeCustomer(index) {
      this.customerSales.splice(index, 1);
    },
    removeExpensesRow(index) {
      this.expenses.splice(index, 1);
    },
    addFailedTransaction(payload) {
      this.failedTransactions.push(payload);
    },
    removeFailedTransaction(index) {
      this.failedTransactions.splice(index, 1);
    },
    addRTTRow() {
      this.trackInputAmount.returnToTank.push({ productId: null, liters: 0 });
    },
    removeRTTRow(index) {
      this.trackInputAmount.returnToTank.splice(index, 1);
    },
    finalizeAndApproveFuelSales() {
      if (
        !this.isAccountsTallied ||
        this.totalFuelRecordings === 0 ||
        Number(
          this.totalFuelRecordings - this.trackInputAmount.totalFuelSales
        ) > 15
      ) {
        this.$store.dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `Sales not tallied hence cannot be approved. Amount can be +/- ${
              this.currency.length > 0 ? this.currency[0].symbol : "GHS"
            } 15`,
            status: "red"
          },
          { root: true }
        );

        return;
      }

      // close sales
      const pumps$ = from(this.pendingFuelSales).pipe(
        map(record => record.values),
        mergeMap(pumps =>
          from(pumps).pipe(
            map(pump => {
              return {
                id: pump?.id,
                nozzleId: pump?.nozzleId?.id,
                price: pump?.price,
                openingMeter: Number(pump?.openingMeter),
                closingMeter: Number(pump?.closingMeter),
                salesInLiters: Number(pump?.meterSold),
                totalAmountOfMeterSold: Number(pump?.totalAmountOfMeterSold),
                dateAssigned: pump?.salesDate,
                productId: pump?.productId?.id,
                productName: pump?.productId?.name,
                pumpAssignAttendantId: pump.pumpAssignAttendantId?.id,
                totalAmountSold: Number(pump.totalAmountSold) ?? 0
              };
            }),
            toArray()
          )
        ),
        toArray()
      );
      pumps$.subscribe(results => {
        // calculate rtt by price if available
        let rttPayload = this.trackInputAmount.returnToTank.filter(
          rtt => Number(rtt.liters) > 0
        );
        let rttAmounToDeduct = rttPayload
          .map(({ productId, liters }) => {
            const productPrice = productId?.prices
              ? productId.prices
              : this.products.find(product => product.id === productId)?.prices;
            return {
              price: Number(productPrice),
              liters: Number(liters)
            };
          })
          .reduce((acc, cur) => (acc += cur.price * cur.liters), 0);
        let payload = {
          sales: results,
          closingSaleDate: moment(this.date).format("YYYY-MM-DD"),
          totalTrackAmount: {
            ...this.trackInputAmount,
            totalFuelSales: roundAccurately(
              this.trackInputAmount.totalFuelSales - rttAmounToDeduct,
              2
            ),
            totalFailedTransactions: this.sum(
              this.failedTransactions,
              "amount"
            ),
            totalCreditCollection: this.sum(
              this.customerSales,
              "creditCollection"
            ),
            totalCreditSales: this.sum(this.customerSales, "creditSales"),
            totalExpenses: this.sum(this.expenses, "value"),
            totalBills: this.sum(this.bills, "amount"),
            totalFuelRevenueVariation: roundAccurately(
              this.totalFuelRecordings - this.trackInputAmount.totalFuelSales,
              2
            )
          },
          isSaleHalfway: false,
          segregations: {
            customerSales: this.customerSales,
            expenses: this.expenses,
            bills: this.bills,
            failedTransactions: this.failedTransactions,
            recordFuelSegregation: this.recordFuelSegregation.map(
              fuelSegregation => {
                return {
                  amount: Number(fuelSegregation.amount),
                  mode: fuelSegregation?.mode?.id ?? fuelSegregation.mode,
                  modeName:
                    fuelSegregation?.mode?.name ?? fuelSegregation.modeName,
                  modeSlug:
                    fuelSegregation?.mode?.slug ?? fuelSegregation?.modeSlug
                };
              }
            )
          }
        };

        this.$store.dispatch("sales/approvalOfFuelSales", {
          branchId: this.branchId,
          ...payload
        });
      });
    }
  },
  created() {
    this.$store.dispatch("prices/modeOfPaymentList");
    this.$store.dispatch("products/list", {
      isPaginated: false,
      page: 1,
      limit: 15
    });
  }
};
</script>
